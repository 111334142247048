import { ListingSearchQueryResult } from '~/libs/api/models/listing-search-query-result';
import * as t from '../types';

export const setOfferChosenVehicle = (vehicle: ListingSearchQueryResult) => dispatch => {
  dispatch({
    type: t.SET_VEHICLE_DATA_OFFER,
    payload: vehicle,
  });
};

export const setOfferStep = (step: number) => dispatch => {
  dispatch({
    type: t.SET_OFFER_STEP,
    payload: step,
  });
};

export const setOfferUserData = (customerData: any) => dispatch => {
  dispatch({
    type: t.SET_USER_DATA_OFFER,
    payload: customerData,
  });
};

export const setCustomOfferPriceConfirmed = (isConfirmed: boolean) => dispatch => {
  dispatch({
    type: t.SET_CUSTOM_OFFER_PRICE_CONFIRMED,
    payload: isConfirmed,
  });
};

export const setCustomOfferCalculation = payload => dispatch => {
  dispatch({
    type: t.SET_CUSTOM_OFFER_CALCULATION,
    payload,
  });
};

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { ListingBadgeType } from '~/libs/api/models/listing-badge-type';

interface CarItemBadgeProps {
  type: ListingBadgeType;
}

export const CarItemBadge = ({ type }: CarItemBadgeProps) => {
  const textColor = useMemo(() => {
    switch (type) {
      case ListingBadgeType.CarLevel_Build2022:
      case ListingBadgeType.CarLevel_Build2023:
      case ListingBadgeType.CarLevel_Build2024:
      case ListingBadgeType.CarLevel_NewListing:
      case ListingBadgeType.CarLevel_SuperiorEquipment:
      case ListingBadgeType.CarLevel_WinterEquipment:
      case ListingBadgeType.CarLevel_Egoe:
      case ListingBadgeType.CarLevel_Bonus80EUR:
      case ListingBadgeType.Top_HitOfTheMonth:
      case ListingBadgeType.Top_TopRV:
        return '#1F2244';

      default:
        return '#fff';
    }
  }, [type]);

  const bgColor = useMemo(() => {
    switch (type) {
      case ListingBadgeType.CarLevel_Build2022:
      case ListingBadgeType.CarLevel_Build2023:
      case ListingBadgeType.CarLevel_Build2024:
      case ListingBadgeType.CarLevel_NewListing:
      case ListingBadgeType.CarLevel_SuperiorEquipment:
      case ListingBadgeType.CarLevel_WinterEquipment:
      case ListingBadgeType.CarLevel_Egoe:
        return '#EDEAE3';

      case ListingBadgeType.Top_HitOfTheMonth:
      case ListingBadgeType.Top_TopRV:
        return '#FFC107';

      case ListingBadgeType.SpecialOffer_SpecialOffer:
      case ListingBadgeType.SpecialOffer_PromoOffer:
      case ListingBadgeType.SpecialOffer_From3Days:
      case ListingBadgeType.SpecialOffer_FirstMinute2024:
        return '#FF5E55';

      case ListingBadgeType.Recommendation_ForFamilies:
      case ListingBadgeType.Recommendation_ForCyclist:
      case ListingBadgeType.Recommendation_ForCouples:
      case ListingBadgeType.Recommendation_RecommendedByUs:
        return '#1F2244';

      default:
        return '#fff';
    }
  }, [type]);

  const text = useMemo(() => {
    switch (type) {
      case ListingBadgeType.CarLevel_Build2022:
        return <Trans id="carBox.badges.CarLevel_Build2022" message="New car 2022" />;
      case ListingBadgeType.CarLevel_Build2023:
        return <Trans id="carBox.badges.CarLevel_Build2023" message="New car 2023" />;
      case ListingBadgeType.CarLevel_Build2024:
        return <Trans id="carBox.badges.CarLevel_Build2024" message="New car 2024" />;
      case ListingBadgeType.CarLevel_NewListing:
        return <Trans id="carBox.badges.CarLevel_NewListing" message="Newly Added" />;
      case ListingBadgeType.CarLevel_SuperiorEquipment:
        return <Trans id="carBox.badges.CarLevel_SuperiorEquipment" message="Superior Equipment" />;
      case ListingBadgeType.CarLevel_WinterEquipment:
        return <Trans id="carBox.badges.CarLevel_WinterEquipment" message="Winter Equipment" />;
      case ListingBadgeType.CarLevel_Egoe:
        return <Trans id="carBox.badges.CarLevel_Egoe" message="Egoe" />;
      case ListingBadgeType.CarLevel_Bonus80EUR:
        return <Trans id="carBox.badges.CarLevel_Bonus80EUR" message="Bonus 80 €" />;

      case ListingBadgeType.SpecialOffer_SpecialOffer:
        return <Trans id="carBox.badges.SpecialOffer_SpecialOffer" message="Special offer" />;
      case ListingBadgeType.SpecialOffer_PromoOffer:
        return <Trans id="carBox.badges.SpecialOffer_PromoOffer" message="Promo offer" />;
      case ListingBadgeType.SpecialOffer_From3Days:
        return <Trans id="carBox.badges.SpecialOffer_From3Days" message="From 3 days" />;
      case ListingBadgeType.SpecialOffer_FirstMinute2024:
        return <Trans id="carBox.badges.SpecialOffer_FirstMinute2024" message="First Minute 2024" />;

      case ListingBadgeType.Recommendation_ForCyclist:
        return <Trans id="carBox.badges.Recommendation_ForCyclist" message="Best for cyclists" />;
      case ListingBadgeType.Recommendation_ForFamilies:
        return <Trans id="carBox.badges.Recommendation_ForFamilies" message="Best for families" />;
      case ListingBadgeType.Recommendation_ForCouples:
        return <Trans id="carBox.badges.Recommendation_ForCouples" message="Best for couples" />;
      case ListingBadgeType.Recommendation_RecommendedByUs:
        return <Trans id="carBox.badges.Recommendation_RecommendedByUs" message="Recommended by us" />;

      case ListingBadgeType.Top_HitOfTheMonth:
        return <Trans id="carBox.badges.Top_HitOfTheMonth" message="Hit of the month" />;
      case ListingBadgeType.Top_TopRV:
        return <Trans id="carBox.badges.Top_TopRV" message="Top RV" />;

      default:
        break;
    }

    return type;
  }, [type]);

  return (
    <BadgeWrapper
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <BadgeText>{text}</BadgeText>
    </BadgeWrapper>
  );
};

const BadgesWrapper = styled.div`
  display: inline-block;
  position: absolute;
  z-index: 99;
  margin: 16px;
`;

const BadgeWrapper = styled.div`
  display: inline-block;
  position: relative;
  z-index: 99;
  padding: 4px 6px 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
  letter-spacing: normal;
  text-transform: uppercase;
  line-height: 1.33;
  font-stretch: normal;
  font-style: normal;
  font-family: var(--font-poppins);
  font-size: 12px;
`;

const BadgeText = styled.span`
  font-weight: 600 !important;
`;

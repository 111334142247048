import React from 'react';
import Tooltip from 'ui/components/ToolTip';
import styled from 'styled-components';
import 'rc-tooltip/assets/bootstrap_white.css';
import { Trans } from '@lingui/react';

const InstantBookToolTipIcon = () => (
  <Wrapper>
    <Tooltip
      placementProp="top"
      heading={<Trans id="labels.instantBooking" message="Okamžitá rezervace" />}
      text={
        <Trans
          id="tooltips.instantBooking.content"
          message="Tento karavan si můžete ve volném termínu okamžitě rezervovat."
        />
      }
    >
      <Icon className="icon-action-instant" id="test_ib_icon_filter" />
    </Tooltip>
  </Wrapper>
);

const ContentText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ff5e55;
  margin-left: 8px;
`;

const Wrapper = styled.span`
  position: relative;
  vertical-align: middle;
  font-size: 21px;
`;

const ToolTipContent = styled.div`
  background-color: #1f2244 !important;
  font-family: var(--font-poppins);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const Heading = styled.div`
  font-weight: 600;
  line-height: 1.33;
  color: var(--color-beige);
  font-family: var(--font-poppins);
  margin-bottom: 8px;
`;
const Content = styled.div`
  font-family: var(--font-poppins);
`;

const Icon = styled.i`
  margin-left: 4px;
  cursor: pointer;
  color: var(--color-orange);
  font-size: 24px;
`;

export default InstantBookToolTipIcon;

/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-sequences */
/* eslint-disable max-len */
// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Flickity from 'react-flickity-component';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Trans } from '@lingui/react';
import { convertToSlug, getVehicleType } from '~/src/components/carDetail/utils';
import { searchInfoSelector } from '~/src/redux/selectors/search';
import { getFormattedPrice } from '~/src/utils/price';
import { getLayoutFillNextImage } from '../utils/images';
import { trackEEGAProductClick, tryCall } from '../utils/analytics';
import { SearchInfoReduxData } from '../redux/reducers/search';
import { PrimaryButton, SecondaryButton } from '~/src/styles/buttons';
import { routes } from '../utils/routing';
import { hideDesktopOrPhoneModal } from './modal/utils';
import { screenSelector } from '~/src/redux/selectors/screen';
import { setOfferChosenVehicle, setOfferStep } from '~/src/redux/actions/offer';
import { offerStepSelector } from '~/src/redux/selectors/offer';
import LocalizedLink from '~/src/components/LocalizedLink';
import { trackProductClickDataLayer, tryCallDataLayerEvent } from '../utils/dataLayer';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { TypedIBrowser } from '~/src/redux/reducers/rootReducer';
import { CarItemBadge } from '~/src/components/CarItemBadge';
import { SettingsProps } from '~/src/redux/reducers/settings';
import { TransmissionType } from '~/libs/api/models/transmission-type';
import InstantBookToolTipIcon from '~/src/components/InstantBookToolTipIcon';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { staticHeadlineTextStyles } from 'ui/components/primitives/typography/CustomTypography';
import { mediaMdMax } from 'ui/utils/breakpoints';
import { FlexAlignCenter } from 'ui/primitives/flex';
import { Typography } from '@mui/material';
import { Box } from 'ui/primitives/box';
import { getLocalizedTitle } from '~/src/utils/translations';

const flickityOptions = {
  initialIndex: 0,
  contain: true,
  pageDots: false,
  arrowShape: '',
  draggable: false,
  fullscreen: true,
};

interface CarItemProps {
  carDataProp: any;
  searchInfo: SearchInfoReduxData;
  hasSpecialPrice?: boolean;
  hasSpecialPriceWithDates?: boolean;
  dateParamForSpecialOffer?: string;
  bookingViewVersion?: boolean;
  checkAvailabilityViewVersion?: boolean;
  isNightPrice?: boolean;
  isModal?: boolean;
  screen: TypedIBrowser;
  dispatch: any;
  offerStep: number;
  isWithoutDates?: boolean;
  settings: SettingsProps;
  unsetWidth?: boolean;
  hidePrice?: boolean;
  isOperator?: boolean;
  isManager?: boolean;
  bookingData?: any;
  showCarType?: boolean;
  dataLayerListName?: string;
  firstImageFetchPriority?: 'high' | 'low';
}

export const caravanSearchSizes =
  '(min-width: 1140px) 390px, (min-width: 1024px) calc((100vw - 64px) * .285), (min-width: 601px) calc((100vw - 32px) * .485), calc(100vw - 32px)';

const contactIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_365_504)">
      <path
        d="M12.0012 5.00001C14.2103 5.00001 16.0012 6.79087 16.0012 9.00101C15.9984 10.4189 15.245 11.7189 14.0394 12.4307L14.0008 12.452L14.0012 15.5C14.0012 15.9193 13.5241 16.1415 13.2054 15.9036L13.1476 15.8536L10.2944 13L10.0012 13C7.85018 13 6.09573 11.3022 6.00488 9.17352L6.00119 9.00001C6.00119 6.79087 7.79205 5.00001 10.0012 5.00001H12.0012ZM12.0012 6.00001H10.0012C8.34433 6.00001 7.00119 7.34316 7.00119 9.00001C7.00119 10.6569 8.34433 12 10.0012 12H10.5012C10.6338 12 10.761 12.0527 10.8547 12.1465L13.0008 14.2928L13.0012 12.1507C13.0012 11.9849 13.0831 11.8319 13.2166 11.7395L13.2875 11.6986C14.3319 11.2049 14.9989 10.1542 15.0012 9.00001C15.0012 7.34316 13.658 6.00001 12.0012 6.00001ZM6.00125 0C7.7702 0.000227653 9.32908 1.16196 9.83497 2.85702C9.91394 3.12163 9.76346 3.40015 9.49885 3.47913C9.23424 3.5581 8.95571 3.40761 8.87674 3.143C8.51245 1.92242 7.42026 1.07052 6.15961 1.00417L6.00119 1L4.00003 0.999999C2.59831 0.996747 1.38213 1.96686 1.07373 3.33424C0.76533 4.70161 1.44735 6.09984 2.71476 6.69858C2.86466 6.7694 2.96802 6.90887 2.9945 7.06907L3.00119 7.15068L3.00085 9.2928L4.14763 8.14646C4.3212 7.97289 4.59063 7.9536 4.78549 8.0886L4.85474 8.14646C5.02831 8.32002 5.04759 8.58945 4.9126 8.78431L4.85474 8.85356L2.85474 10.8536C2.55829 11.15 2.06378 10.9698 2.00662 10.5762L2.00119 10.5L2.00085 7.452L1.96001 7.42911C0.534059 6.58662 -0.230093 4.93996 0.0614722 3.29675L0.0982328 3.11422C0.496411 1.34879 2.02882 0.079716 3.82112 0.00360842L4.00119 0H6.00125Z"
        fill="#1F2244"
      />
    </g>
    <defs>
      <clipPath id="clip0_365_504">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CarItem = ({
  carDataProp,
  searchInfo,
  hasSpecialPrice = false,
  hasSpecialPriceWithDates = false,
  dateParamForSpecialOffer = null,
  bookingViewVersion = false,
  checkAvailabilityViewVersion = false,
  isNightPrice = false,
  isModal = false,
  isWithoutDates = false,
  screen,
  dispatch,
  offerStep,
  dataLayerListName = undefined,
  settings,
  unsetWidth,
  isOperator,
  hidePrice = false,
  bookingData = null,
  isManager = false,
  showCarType = true,
  firstImageFetchPriority = undefined,
}: CarItemProps) => {
  const carouselRef = useRef(null);
  const [carouselItem, setCarouselItem] = useState(0);
  useEffect(() => {
    if (!bookingViewVersion) {
      carouselRef.current?.element?.addEventListener('click', (event) => {
        const tagName = event.target.tagName.toUpperCase();
        if (tagName === 'BUTTON' || tagName === 'SVG') {
          event.preventDefault();
        }
      });

      const onSlide = (slide) => {
        setCarouselItem(slide);
      };

      carouselRef.current.on('change', onSlide);
    }

    return () => {
      if (!bookingViewVersion && typeof onSlide === 'function') {
        carouselRef.current.off('change', onSlide);
      }
    };
  }, []);

  const getCarItemQuery = () => {
    let dateParam = '';

    if (searchInfo.selectedDays && dateParamForSpecialOffer === null) {
      dateParam = `dateFrom=${dayjs(searchInfo.selectedDays.startDate).format('YYYY-MM-DD')}&dateTo=${dayjs(
        searchInfo.selectedDays.endDate
      ).format('YYYY-MM-DD')}`;
    }

    if (hasSpecialPriceWithDates && !isWithoutDates) {
      dateParam = `dateFrom=${dayjs(carDataProp.startDate).format('YYYY-MM-DD')}&dateTo=${dayjs(
        carDataProp.endDate
      ).format('YYYY-MM-DD')}`;
    }

    return dateParam;
  };

  const selectCar = () => {
    // console.log('selected', carDataProp);
    dispatch(setOfferChosenVehicle(carDataProp));
    hideDesktopOrPhoneModal(screen, dispatch);

    if (offerStep < 2) {
      dispatch(setOfferStep(2));
    }
  };

  const isBookinVersionBox = bookingViewVersion ? 'bookingVersion' : '';
  const isModalBox = isModal ? 'isModal' : '';
  const unsetWidthClass = unsetWidth ? 'unsetWidth' : '';
  const hidePriceClass = hidePrice ? 'hidePriceBar' : '';
  const withButton = checkAvailabilityViewVersion ? 'withButton' : '';
  const withButtonAndPrice = checkAvailabilityViewVersion && !hidePrice ? 'showAvaivabilityAndPrice' : '';

  const carBoxClasses = `${isBookinVersionBox} ${isModalBox} ${unsetWidthClass} ${hidePriceClass} ${withButton} ${withButtonAndPrice}`;
  const formatOperatorName = (name: string, company: string) => {
    if (company !== null && company !== name) {
      return `${name} (${company})`;
    }

    return name;
  };
  const getPriceSection = () => {
    if (hidePrice) {
      return <></>;
    }

    if (hasSpecialPrice) {
      return (
        <CarBoxContenFooter>
          <CarBoxContenFooterLeft className="hasSpecialPrice">
            <CarBoxPriceFromTitle className="hasSpecialPrice">
              <Trans id="carBox.content.footer.numOfNights" message="Cena za 3 noci" />
            </CarBoxPriceFromTitle>
          </CarBoxContenFooterLeft>
          <CarBoxContenFooterRight className="hasSpecialPrice">
            <CarBoxPrice>{getFormattedPrice(carDataProp?.minPrice?.new, settings)}</CarBoxPrice>
            <CarBoxPrice>
              <span className="oldPrice">{getFormattedPrice(carDataProp?.minPrice?.total, settings)}</span>
            </CarBoxPrice>
            {carDataProp.instantBookable && <InstantBookToolTipIcon />}
          </CarBoxContenFooterRight>
        </CarBoxContenFooter>
      );
    }

    if (hasSpecialPriceWithDates && carDataProp?.two_prices === true) {
      return (
        <CarBoxContenFooter>
          <CarBoxContenFooterLeft className="hasSpecialPrice">
            <CarBoxPriceFromTitle className="hasSpecialPrice" data-testid="price-from-label">
              {!isWithoutDates ? (
                <>
                  {`${dayjs(carDataProp.startDate).format('D. M.')}
              - ${dayjs(carDataProp.endDate).format('D. M.')}
              `}
                </>
              ) : (
                <Trans id="carBox.content.footer.priceFrom" message="Cena od:" />
              )}
            </CarBoxPriceFromTitle>
          </CarBoxContenFooterLeft>
          <CarBoxContenFooterRight className="hasSpecialPrice">
            <CarBoxPrice className={isNightPrice && !isWithoutDates ? 'smallerFontSize' : ''}>
              {getFormattedPrice(carDataProp?.priceToShow, settings)}
              {isNightPrice && <Trans id="carBox.content.footer.forDay" message="/den" />}
            </CarBoxPrice>
            <CarBoxPrice className={isNightPrice && !isWithoutDates ? 'smallerFontSize' : ''}>
              <span className="oldPrice">{getFormattedPrice(carDataProp?.oldPrice, settings)}</span>
            </CarBoxPrice>
            {carDataProp.instantBookable && <InstantBookToolTipIcon />}
          </CarBoxContenFooterRight>
        </CarBoxContenFooter>
      );
    }

    if (hasSpecialPriceWithDates) {
      return (
        <CarBoxContenFooter>
          <CarBoxContenFooterLeft className="hasSpecialPriceWithDate">
            <CarBoxPriceFromTitle>
              {`${dayjs(carDataProp.startDate).format('D. M.')}
              - ${dayjs(carDataProp.endDate).format('D. M.')}
              `}
            </CarBoxPriceFromTitle>
          </CarBoxContenFooterLeft>
          <CarBoxContenFooterRight className="hasSpecialPriceWithDate">
            <CarBoxPrice>
              {typeof carDataProp.priceToShow !== 'number'
                ? carDataProp.priceToShow
                : getFormattedPrice(carDataProp.priceToShow, settings)}
              <Trans id="carBox.content.footer.forDay" message="/den" />
            </CarBoxPrice>
            {carDataProp.instantBookable && <InstantBookToolTipIcon />}
          </CarBoxContenFooterRight>
        </CarBoxContenFooter>
      );
    }

    if (bookingViewVersion && isOperator) {
      return (
        <CarBoxContenFooter>
          <CarFooterDataOperatorView>
            {bookingData?.licensePlate && (
              <>
                <Trans id="labels.carID" message="SPZ" />: {bookingData?.licensePlate}
                <br />
                <br />
              </>
            )}

            {bookingData?.vinCode && (
              <>
                <Trans id="labels.car.vinCode" />: {bookingData?.vinCode}
              </>
            )}
          </CarFooterDataOperatorView>
        </CarBoxContenFooter>
      );
    }

    if (bookingViewVersion) {
      return (
        <CarBoxContenFooter>
          {isOperator && <h1>SPZ</h1>}

          <OwnerWrapper>
            <OwnerInfo>
              <OwnerImage
                width="32"
                height="32"
                alt="Owner Image"
                onError={(e: any) => {
                  e.target.src = '/static/compressedImages/avatar_placeholder.png';
                }}
                src={
                  carDataProp?.operator?.picture
                    ? carDataProp?.operator?.picture
                    : '/static/compressedImages/avatar_placeholder.png'
                }
              />

              <OwnerName>{formatOperatorName(carDataProp?.operatorName, carDataProp?.operatorAccountName)}</OwnerName>
              <VerifiedIcon className="icon-verified" />
            </OwnerInfo>
            {!isManager && carDataProp?.operatorMessagingEnabled && (
              <LocalizedLink href={routes.inbox} passHref={true}>
                <ContactOwnerCTA>
                  <div className="icon">{contactIcon}</div>
                  <div>
                    <Trans id="carDetail.contactOwner.cta.label" message="Message owner" />
                  </div>
                </ContactOwnerCTA>
              </LocalizedLink>
            )}
          </OwnerWrapper>
        </CarBoxContenFooter>
      );
    }

    return (
      <>
        <CarBoxContenFooter>
          <CarBoxContenFooterLeft>
            <CarBoxPriceFromTitle data-testid="price-from-label">
              <Trans id="carBox.content.footer.priceFrom" message="Cena od:" />
            </CarBoxPriceFromTitle>
          </CarBoxContenFooterLeft>
          <CarBoxContenFooterRight>
            <CarBoxPrice>
              {getFormattedPrice(carDataProp?.minPrice?.total, settings)}
              <Trans id="carBox.content.footer.forDay" message="/den" />
              {carDataProp.instantBookable && <InstantBookToolTipIcon />}
            </CarBoxPrice>
          </CarBoxContenFooterRight>
        </CarBoxContenFooter>
        {isModal && (
          <CarBoxContenFooterButtons>
            <SelectCarButtonWrapper>
              <PrimaryButton
                dataTestId="choose-car-offer"
                onClick={() => {
                  selectCar(carDataProp);
                }}
              >
                <Trans id="button.labels.chooseCaravan" message="Select campervan" />
              </PrimaryButton>
            </SelectCarButtonWrapper>
            <DetailCarButtonWrapper>
              <LocalizedLink
                href={routes.carDetail}
                linkParams={{
                  id: carDataProp.id,
                  name: carDataProp?.urlSlug || convertToSlug(carDataProp.title),
                }}
                query={getCarItemQuery()}
                prefetch={false}
                newWindow={true}
              >
                <SecondaryButton>
                  <Trans id="button.labels.caravanDetail" message="Detail karavanu" />
                </SecondaryButton>
              </LocalizedLink>
            </DetailCarButtonWrapper>
          </CarBoxContenFooterButtons>
        )}
      </>
    );
  };

  return (
    <LocalizedLink
      legacyBehavior={true}
      href={routes.carDetail}
      linkParams={{
        id: carDataProp.id,
        name: carDataProp?.urlSlug || convertToSlug(carDataProp.title),
      }}
      query={getCarItemQuery()}
      prefetch={false}
      passHref={!isModal}
      disabled={isModal}
    >
      <CarBox
        data-testid="car-item"
        target="_blank"
        onClick={(event) => {
          const tagName = event.target.tagName.toUpperCase();
          if (tagName === 'SVG' || tagName === 'BUTTON') return;
          if (bookingViewVersion) return;
          tryCall(() => {
            tryCallDataLayerEvent(() => {
              trackProductClickDataLayer(carDataProp, dataLayerListName);
            });
            trackEEGAProductClick(carDataProp);
          });
        }}
        className={carBoxClasses}
      >
        {bookingViewVersion ? (
          <CarBoxImageWrapper>
            <CarBoxImageWrapper>
              {getLayoutFillNextImage(carDataProp.pictures[0], 'caravan image', undefined, caravanSearchSizes)}
            </CarBoxImageWrapper>
          </CarBoxImageWrapper>
        ) : (
          <CarBoxImageWrapper>
            {carDataProp?.badges?.length > 0 && (
              <CarItemBadgesWrapper>
                {carDataProp?.badges?.map((t) => <CarItemBadge key={t.type} type={t.type} />)}
              </CarItemBadgesWrapper>
            )}
            <Flickity
              flickityRef={(c) => {
                carouselRef.current = c;
              }}
              className="carousel"
              elementType="div"
              options={flickityOptions}
              disableImagesLoaded={true}
            >
              {carDataProp.pictures.map((picture, id) => (
                <CarBoxImageWrapper key={picture.guid}>
                  {id === 0 &&
                    getLayoutFillNextImage(
                      picture,
                      'caravan image',
                      undefined,
                      caravanSearchSizes,
                      undefined,
                      firstImageFetchPriority
                    )}
                  {id > 0 && carouselItem !== id && <div />}
                  {id > 0 &&
                    carouselItem === id &&
                    getLayoutFillNextImage(picture, 'caravan image', undefined, caravanSearchSizes)}
                </CarBoxImageWrapper>
              ))}
            </Flickity>
          </CarBoxImageWrapper>
        )}

        <CarBoxContent>
          <CarBoxContentHead>
            <CarBoxContentHeadLeft>
              {showCarType && (
                <CarBoxType>
                  <Trans id={getVehicleType(carDataProp.vehicleType)} />
                </CarBoxType>
              )}
              {bookingData?.licensePlate ? (
                <Box $mb={2}>
                  <Typography variant="subtitle2">{bookingData?.licensePlate}</Typography>
                </Box>
              ) : null}
              <CarBoxTitle>
                {getLocalizedTitle(carDataProp.translations, settings.language) || carDataProp.title}
              </CarBoxTitle>
            </CarBoxContentHeadLeft>
            {/* <CarBoxContentHeadRight> */}
            {/* <i className="icon active icon-eco"></i>
              <i className="icon icon-eco"></i>
              <i className="icon icon-eco"></i> */}
            {/* </CarBoxContentHeadRight> */}
          </CarBoxContentHead>
          <CarBoxContentBody>
            {!bookingViewVersion && <CarBoxPlace>{carDataProp.location}</CarBoxPlace>}
            {!isOperator && (
              <CarBoxEquipments>
                <FlexAlignCenter>
                  {carDataProp.passengersCapacity > 0 && (
                    <CarBoxEquipmentsItem>
                      <Icon className="icon icon-seats" />
                      <CarBoxEquipmentsItemCount>{carDataProp.passengersCapacity}</CarBoxEquipmentsItemCount>
                    </CarBoxEquipmentsItem>
                  )}

                  <CarBoxEquipmentsItem>
                    <Icon className="icon icon-bed" />
                    <CarBoxEquipmentsItemCount>{carDataProp.sleepCapacity}</CarBoxEquipmentsItemCount>
                  </CarBoxEquipmentsItem>

                  {carDataProp.transmissionType === TransmissionType.Automatic && (
                    <CarBoxEquipmentsItem>
                      <Icon className="icon icon-pnd" />
                      <CarBoxEquipmentsItemCount>Aut.</CarBoxEquipmentsItemCount>
                    </CarBoxEquipmentsItem>
                  )}
                  {carDataProp.transmissionType === TransmissionType.Manual && (
                    <CarBoxEquipmentsItem>
                      <Icon className="icon-icon-specification-manual" />
                      <CarBoxEquipmentsItemCount>Man.</CarBoxEquipmentsItemCount>
                    </CarBoxEquipmentsItem>
                  )}

                  {carDataProp.toilet !== 'NoToilet' && (
                    <CarBoxEquipmentsItem>
                      <Icon className="icon icon-wc" />
                    </CarBoxEquipmentsItem>
                  )}

                  {carDataProp.shower !== 'NoShower' && (
                    <CarBoxEquipmentsItem>
                      <Icon className="icon icon-shower" />
                    </CarBoxEquipmentsItem>
                  )}
                </FlexAlignCenter>

                {bookingViewVersion && (
                  <CaravatDetailCTA>
                    <Trans id="button.labels.caravanDetail" message="Detail karavanu" />
                  </CaravatDetailCTA>
                )}
              </CarBoxEquipments>
            )}
          </CarBoxContentBody>
        </CarBoxContent>
        {getPriceSection()}
        {checkAvailabilityViewVersion && (
          <CaravatDetailCTABottom>
            <PrimaryButton btnId="cta" size="sm">
              <Trans id="button.labels.checkAvailability" />
            </PrimaryButton>
          </CaravatDetailCTABottom>
        )}
      </CarBox>
    </LocalizedLink>
  );
};

const mapStateToProps = (state) => ({
  searchInfo: searchInfoSelector(state),
  screen: screenSelector(state),
  offerStep: offerStepSelector(state),
  settings: state.settings,
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(CarItem);

const VerifiedIcon = styled.i`
  color: #2ea092;
  font-size: 16px;
`;

const OwnerImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
`;

const OwnerName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-dark-blue);
`;

const OwnerWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font-poppins);
  width: 100%;
  gap: 10px;
  @media ${deviceMaxWidth.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CaravatDetailCTA = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${resolveThemeValue(tokens.color.text.textLink, 'var(--color-dark-blue)')};
  text-decoration: underline;
  margin-left: auto;
  cursor: pointer;
  pointer-events: auto;
  flex-shrink: 0;

  .isFWC .bookingVersion & {
    padding-top: ${resolveThemeValue(tokens.spacing.spacingL)};
    margin-left: 0;
    font-size: 16px;
    margin-top: ${resolveThemeValue(tokens.spacing.spacingL)};
    border-top: 1px solid ${resolveThemeValue(tokens.color.border.border)};
  }
`;

const CaravatDetailCTABottom = styled.div`
  margin: 0 16px;
  padding-bottom: 12px;
`;

const ContactOwnerCTA = styled.div`
  margin-left: auto;
  text-align: right;
  cursor: pointer;
  pointer-events: auto;
  padding: 8px 28px;
  background: #edeae3;
  border-radius: 8px;
  text-align: center;
  color: #1f2244;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  div.icon {
    margin-right: 8px;
  }

  div {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
  }

  @media ${deviceMaxWidth.phone} {
    margin-left: 0px;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 8px;
  }
`;

const CarBox = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 418px;
  height: fit-content;
  max-height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  border: solid 1px ${resolveThemeValue(tokens.color.border.border, 'var(--color-beige)')};
  background-color: var(--color-white);
  max-width: 392px;

  &.hidePriceBar {
    height: 380px;
  }

  &.withButton {
    height: 428px;
  }

  &.isModal {
    height: auto;
  }

  &.unsetWidth {
    max-width: unset;
  }

  &.showAvaivabilityAndPrice {
    height: 465px;
  }

  &:hover {
    box-shadow: 0 4px 12px 0 rgba(31, 34, 68, 0.12);
  }

  &.bookingVersion {
    height: 100%;
    padding-bottom: 16px;
    cursor: unset;
    pointer-events: none;
    max-width: unset;

    &:hover {
      box-shadow: unset;
    }

    .isFWC & {
      flex-direction: row;
      min-height: unset;
      padding-bottom: 0;

      ${mediaMdMax} {
        flex-direction: column;
      }
    }
  }

  .carousel,
  .flickity-slider {
    height: 190px;
    width: 100%;
  }

  .flickity-prev-next-button {
    background: transparent !important;

    &:before {
      content: '';
      background-image: url('/static/images/vectors/flickity_arrow_white.svg');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      position: absolute;
      top: calc(50% - 12px);
      z-index: 0;
    }

    /* position: relative; */
  }

  .flickity-prev-next-button.previous {
    left: 0px;
  }

  .flickity-prev-next-button.next {
    right: 20px;

    &:before {
      transform: rotate(180deg);
    }
  }
`;

const CarItemBadgesWrapper = styled.div`
  position: absolute;
  margin: 16px;
`;

const CarBoxImageWrapper = styled.div`
  width: 100%;
  height: 228px;

  margin: 0 0 12px;
  position: relative;
  background-color: #d8d8d8;
  overflow: hidden;
  display: flex;

  .isFWC .bookingVersion & {
    margin: 0;
    max-width: 320px;
    ${mediaMdMax} {
      max-width: unset;
    }
  }

  /* prettier-ignore */

  ${CarBox}.isModal & {
    height: 190px;
  }
`;

const CarBoxContent = styled.div`
  padding: 0 16px 0 16px;
  .isFWC .bookingVersion & {
    margin: auto 0;
    width: 100%;
    ${mediaMdMax} {
      padding: ${resolveThemeValue(tokens.spacing.spacingL)};
      margin: 0;
    }
  }
`;

const CarBoxContentHead = styled.div`
  border-bottom: 1px solid var(--color-beige);
  display: flex;
  padding-bottom: 8px;
`;

const CarBoxContentHeadLeft = styled.div`
  width: 60.7508532%;
  text-overflow: ellipsis;
  width: 178px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const CarBoxType = styled.span`
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1;
  color: var(--color-orange);
  display: block;
  text-transform: uppercase;
  margin-bottom: 4px;

  .isFWC .bookingVersion & {
    display: none;
  }
`;

const CarBoxEquipments = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  .isFWC .bookingVersion & {
    flex-direction: column;
    align-items: unset;
  }
`;

const CarBoxEquipmentsItem = styled.li`
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  display: flex;
  align-items: center;
  letter-spacing: normal;
  color: #1f2244;
  margin-right: 12px;

  .isFWC .bookingVersion & {
    border: 1px solid ${resolveThemeValue(tokens.color.border.border)};
    padding: ${resolveThemeValue(tokens.spacing.spacingXs)} ${resolveThemeValue(tokens.spacing.spacingS)};
    border-radius: ${resolveThemeValue(tokens.radius.radiusL)};
  }
`;

const Icon = styled.i`
  font-size: 1.5rem !important;

  &:before {
    color: #9c8c8c !important;
  }
`;
const CarBoxEquipmentsItemCount = styled.span`
  padding-left: 4px;
`;

const CarBoxTitle = styled.h4`
  font-family: var(--font-baloo);
  font-size: 24px;
  margin: 0;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #1f2244;
  transition: all 0.3s;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  ${CarBox}:hover & {
    color: ${resolveThemeValue(tokens.color.text.textLink, '#119383')};
  }

  .isFWC .bookingVersion & {
    ${staticHeadlineTextStyles('h4')}
  }
`;

const CarBoxPriceFromTitle = styled.h4`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  white-space: nowrap;
  letter-spacing: normal;
  color: var(--color-dark-grey);
  line-height: 21px;
  margin: 0;

  &.hasSpecialPrice {
    font-size: 14px;
    line-height: 17px;
  }
`;

const CarBoxPlace = styled.h4`
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1f2244;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CarBoxPrice = styled.h4`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  line-height: 1;
  letter-spacing: normal;
  color: #1f2244;

  span.oldPrice {
    text-decoration: line-through;
    font-style: 200;
    margin-left: 6px;
  }

  &.smallerFontSize {
    font-size: 13px;

    span.oldPrice {
      font-size: 13px;
    }
  }

  i {
    /* color: #fd253f; */
    /* margin-right: 6px; */
    /* font-size: 16px; */
    font-size: 1rem;
    position: relative;
    bottom: 1px;
  }
`;

const CarBoxContentBody = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid var(--color-beige);

  /* prettier-ignore */

  ${CarBox}.hidePriceBar & {
    border-bottom: none;
  }

  &:empty {
    border-bottom: 0px;
    padding: 0px;
  }

  .isFWC .bookingVersion & {
    padding: 0;
  }
`;

const CarBoxContenFooter = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  margin: auto 0;
  .isFWC .bookingVersion & {
    display: none;
  }
`;

const CarBoxContenFooterButtons = styled.div`
  padding: 20px 16px 20px 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectCarButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;

  button {
    font-size: 14px !important;
    padding: 10px 28px !important;
  }
`;

const DetailCarButtonWrapper = styled.div`
  width: 100%;

  button {
    font-size: 14px !important;
    padding: 10px 28px !important;
  }
`;

const CarBoxContenFooterLeft = styled.div`
  width: 30%;

  &.hasSpecialPrice {
    width: 40%;
  }

  &.hasSpecialPriceWithDate {
    width: 65%;
  }
`;

const CarBoxContenFooterRight = styled.div`
  width: 70%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  &.hasSpecialPrice {
    width: 65%;
  }

  &.hasSpecialPriceWithDate {
    width: 50%;
  }

  .icon {
    font-size: 1rem;
    margin-left: 8px;

    &:before {
      color: #fd253f;
    }
  }
`;

const CarFooterDataOperatorView = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #1f2244;
`;

const OwnerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

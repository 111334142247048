import { getLanguageCodeForApi } from 'ui/components/searchBar/utils/language';
import { LanguageCode, TranslationDto } from '~/libs/api';
import { Language } from 'features/utils/routing';

const getTranslation = (translations: TranslationDto[], language: Language) =>
  translations?.find((trans) => trans.language === getLanguageCodeForApi(language)) ||
  translations?.find((trans) => trans.language === LanguageCode.English);

export const getLocalizedTitle = (translations: TranslationDto[], language: Language) => {
  const translation = getTranslation(translations, language);
  return translation?.title;
};

export const getLocalizedDescription = (translations: TranslationDto[], language: Language) => {
  const translation = getTranslation(translations, language);
  return translation?.description;
};

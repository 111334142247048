import { RootState } from '~/src/redux/reducers/rootReducer';

const offerCustomerSelection = (state: RootState) => state.offer.customerData;
const offerStepSelector = (state: RootState) => state.offer.offerStep;
const offerChosenVehicleSelector = (state: RootState) => state.offer.chosenVehicleData;
const customOfferPriceConfirmedSelector = (state: RootState) => state.offer.customOfferPriceConfirmed;
const customOfferCalculationSelector = (state: RootState) => state.offer.customOfferCalculation;

export {
  offerCustomerSelection,
  offerStepSelector,
  offerChosenVehicleSelector,
  customOfferPriceConfirmedSelector,
  customOfferCalculationSelector,
};
